/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";

const APPLICATION_NAME = "TIME";

const getDocumentTitle = (details: string | undefined): string => {
	if (isUndefined(details)) {
		return APPLICATION_NAME;
	}

	return `${details} | ${APPLICATION_NAME}`;
};

export {
	getDocumentTitle,
};
