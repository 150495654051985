/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type DateString,
} from "models/dates-and-time/types";
import {
	type FilterRequestBody,
	type FilterValues,
} from "types/filter";

interface GetRequestBodyFromFilterValuesArgs {
	filterValues: FilterValues;
	periodStartDate: DateString;
	periodEndDate: DateString;
}

const getRequestBodyFromFilterValues = ({
	filterValues,
	periodStartDate,
	periodEndDate,
}: GetRequestBodyFromFilterValuesArgs): FilterRequestBody => {
	return {
		employeeIds: filterValues.employees.map((employee) => {
			return employee.id;
		}),
		locationUids: filterValues.countries.map((location) => {
			return location.id;
		}),
		engagementTypeIds: filterValues.engagementPackages.map((engagementPackage) => {
			return engagementPackage.id;
		}),
		callToActionTipTypes: filterValues.projectTips,
		withGaps: filterValues.gaps,
		withOverreporting: filterValues.overReporting,
		startDate: periodStartDate,
		endDate: periodEndDate,
	};
};

export {
	getRequestBodyFromFilterValues,
};
