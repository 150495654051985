/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import capitalize from "lodash/capitalize";

const toCapitalCase = (stringToFormat: string): string => {
	return capitalize(stringToFormat.replaceAll("_", " "));
};

const pluralRules = new Intl.PluralRules("en-US");

interface PluralizeWordParams {
	count: number;
	singularForm: string;
	pluralForm: string;
}

// Inspired by: https://2ality.com/2019/12/intl-pluralrules.html#a-simple-tool-function-for-pluralization
const pluralizeWord = ({
	count,
	singularForm,
	pluralForm,
}: PluralizeWordParams): string => {
	const grammaticalNumber = pluralRules.select(count);

	switch (grammaticalNumber) {
		case "one": {
			return singularForm;
		}

		case "other": {
			return pluralForm;
		}

		default: {
			throw new Error(`Unknown: ${grammaticalNumber}`);
		}
	}
};

export {
	toCapitalCase,
	pluralizeWord,
};
