/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import replace from "lodash/replace";
import startsWith from "lodash/startsWith";
import toLower from "lodash/toLower";

const EMAIL_PREFIX = "mailto:";

interface EmailParams {
	to: string;
	cc?: string;
	subject?: string;
	body?: string;
}

const getEmailString = ({
	to,
	cc,
	subject,
	body,
}: EmailParams): string => {
	/*
		`new URLSearchParams()` can't be used, because it encodes spaces as "+",
		which are preserved until the email is rendered in the emailing application,
		which is undesirable.
	*/
	const params: string[] = [];

	if (!isUndefined(cc)) {
		params.push(`cc=${cc}`);
	}

	if (!isUndefined(subject)) {
		params.push(`subject=${subject}`);
	}

	if (!isUndefined(body)) {
		params.push(`body=${body}`);
	}

	const paramsString = params.join("&");

	const emailString = `${EMAIL_PREFIX}${to}`;

	if (isEmpty(paramsString)) {
		return emailString;
	}

	return `${emailString}?${paramsString}`;
};

// Encode the email string the same way `window.open` does.
const encodeEmailString = (emailString: string): string => {
	const link = document.createElement("a");

	/*
		`a` tag automatically encodes `href` string. Moreover, it does it the same
		way as `window.open`.
	*/
	link.href = emailString;

	return link.href;
};

const openNativeEmailClient = (emailString: string): void => {
	if (!startsWith(emailString, EMAIL_PREFIX)) {
		throw new Error("The given string is not an email string.");
	}

	window.open(emailString);
};

const createEmailAndOpenNativeEmailClient = (
	emailParams: EmailParams,
): void => {
	const emailString = getEmailString(emailParams);

	openNativeEmailClient(emailString);
};

const generateEmailFromFullName = (fullName = ""): string => {
	const fullNameLowerCased = toLower(fullName);

	const fullNameWithUnderscores = replace(
		fullNameLowerCased,
		/\s+/g,
		"_",
	);

	return `${fullNameWithUnderscores}@epam.com`;
};

export {
	getEmailString,
	encodeEmailString,
	openNativeEmailClient,
	createEmailAndOpenNativeEmailClient,
	generateEmailFromFullName,
	type EmailParams,
};
