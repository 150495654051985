/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";

interface GetDataFromLocalStorageParams<Data> {
	key: string;
	defaultValue: Data;
}

const getDataFromLocalStorage = <Data>({
	key,
	defaultValue,
}: GetDataFromLocalStorageParams<Data>): Data => {
	try {
		const dataFromLocalStorage = localStorage.getItem(key);

		if (!isNull(dataFromLocalStorage)) {
			return JSON.parse(dataFromLocalStorage) as Data;
		}
	} catch {}

	return defaultValue;
};

export {
	getDataFromLocalStorage,
};
