/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import {
	type AxiosError,
} from "axios";
import omit from "lodash/omit";
import pick from "lodash/pick";
import {
	type IStringifyOptions as SerializerOptions,
	stringify as qsStringify,
} from "qs";

import {
	type ApiError,
} from "types/api";

/**
 * @deprecated Use `qs` or `queryParamsSerializerDefault` to build query strings.
 */
const getArrayForQuery = (items: (string | number | boolean)[]): string => {
	return items.join();
};

const DEFAULT_SERIALIZER_OPTIONS: SerializerOptions = {
	encode: false,
};

interface QueryParamsSerializerDefaultParams<
	QueryParams extends Record<string, unknown>,
> {
	queryParams: QueryParams;
	serializerOptions?: SerializerOptions;
}

const queryParamsSerializerDefault = <
	QueryParams extends Record<string, unknown>,
>({
	queryParams,
	serializerOptions,
}: QueryParamsSerializerDefaultParams<QueryParams>): string => {
	return qsStringify(
		queryParams,
		{
			...DEFAULT_SERIALIZER_OPTIONS,
			arrayFormat: "comma",
			...serializerOptions,
		},
	);
};

interface QueryParamsSerializerWithRepeatedKeysParams<
	QueryParams extends Record<string, unknown>,
	QueryParamsKey extends keyof QueryParams,
> extends QueryParamsSerializerDefaultParams<QueryParams> {
	keysToRepeat: QueryParamsKey[];
}

const queryParamsSerializerWithRepeatedKeys = <
	QueryParams extends Record<string, unknown>,
	QueryParamsKey extends keyof QueryParams,
>({
	queryParams,
	keysToRepeat,
	serializerOptions,
}: QueryParamsSerializerWithRepeatedKeysParams<QueryParams, QueryParamsKey>): string => {
	const queryParamsToRepeat = pick(
		queryParams,
		keysToRepeat,
	);
	const otherQueryParams = omit(
		queryParams,
		keysToRepeat,
	);

	const queryStringWithRepeatedKeys = qsStringify(
		queryParamsToRepeat,
		{
			...DEFAULT_SERIALIZER_OPTIONS,
			arrayFormat: "repeat",
			...serializerOptions,
		},
	);

	const queryStringWithOtherParams = queryParamsSerializerDefault({
		queryParams: otherQueryParams,
		serializerOptions,
	});

	return `${queryStringWithOtherParams}&${queryStringWithRepeatedKeys}`;
};

const getApiError = <Data = unknown>(
	error: unknown,
): ApiError<Data> => {
	const typedError = error as AxiosError<Data>;

	return {
		status: typedError.response?.status ?? -1,
		data: typedError,
	} satisfies FetchBaseQueryError;
};

export {
	getArrayForQuery,
	queryParamsSerializerDefault,
	queryParamsSerializerWithRepeatedKeys,
	getApiError,
};
