/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import apm, {
	type AgentConfigOptions,
	type ApmBase,
} from "@elastic/apm-rum";
import isEmpty from "lodash/isEmpty";
import toNumber from "lodash/toNumber";

type ApmAgentConfig = Pick<
	AgentConfigOptions,
	| "serverUrl"
	| "environment"
>;

const getApmAgentConfig = (): ApmAgentConfig => {
	const hostNameMatch = window.location.host.match(/^\w+-\w+|^\w+/gm);

	const hostName = hostNameMatch?.at(0) ?? "";

	if (hostName === "time") {
		return {
			serverUrl: "https://apm.app.epam.com",
			environment: "production",
		};
	}

	return {
		serverUrl: "https://apm-sandbox.cloudapp.epam.com",
		environment: hostName,
	};
};

const startApmAgent = (): ApmBase => {
	const apmAgentConfig = getApmAgentConfig();

	let transactionSampleRate = 1;

	// Excluding empty string, because it is casted to 0, which is useless.
	if (!isEmpty(process.env.REACT_APP_APM_TRANSACTION_SAMPLE_RATE)) {
		const transactionSampleRateFromEnvironmentVariable = toNumber(
			process.env.REACT_APP_APM_TRANSACTION_SAMPLE_RATE,
		);

		if (!Number.isNaN(transactionSampleRateFromEnvironmentVariable)) {
			transactionSampleRate = transactionSampleRateFromEnvironmentVariable;
		}
	}

	const apmAgent = apm({
		serviceName: "time-ui",
		serverUrl: apmAgentConfig.serverUrl,
		serviceVersion: process.env.REACT_APP_APM_VERSION ?? "",
		environment: apmAgentConfig.environment,
		disableInstrumentations: [
			"eventtarget",
		],
		breakdownMetrics: true,
		transactionSampleRate,
		customPageLoadDelay: 3_000,
	});

	apmAgent.setHttpResourceExclusionList([
		// Banner.
		"ebn.epam.com",
		// Google Analytics.
		"googletagmanager.com",
		// Google Analytics.
		"google-analytics.com",
		// Global menu (production).
		"menu.epam.com",
		// Global menu (non-production).
		"globalmenu-stage.epm-ppa.projects.epam.com",
	]);

	apmAgent.addLabels({
		project: "epm-time",
	});

	apmAgent.addLabels({
		// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
		service_type: "ui",
	});

	return apmAgent;
};

export {
	startApmAgent,
};
