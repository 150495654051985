/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import md5 from "blueimp-md5";

import {
	type EmployeeId,
} from "models/employees/types";

const CDN_HOST = "https://static.cdn.epam.com/avatar/";

enum AvatarSize {
	SMALL = "small",
	MEDIUM = "medium",
	LARGE = "large",
}

interface GetAvatarUrlParams {
	employeeId: EmployeeId;
	size: AvatarSize;
}

const getAvatarUrl = ({
	employeeId,
	size,
}: GetAvatarUrlParams): string => {
	return `${CDN_HOST}${md5(`${size}_${employeeId}`)}.jpg`;
};

export {
	AvatarSize,
	getAvatarUrl,
};
