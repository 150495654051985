/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import kebabCase from "lodash/kebabCase";

// TODO: rethink usage of this function.
/**
 * Coverts a given data string to a unified data attribute format for tests.
 */
const toDataAttribute = (string: string): string => {
	return kebabCase(string);
};

export {
	toDataAttribute,
};
